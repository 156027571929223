.logo-image{
    display: flex;
    justify-content: space-between;
    padding: 0 100px;
}

.button-icon{
    width: 100px;
    height: fit-content;
    background-color: black;
    padding: 10px;
    color: white;
    font-size: 14px;
    border-radius: 5px;
    border: 0.5px solid white;
    display: flex;
    justify-content: space-around;
}

.button-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 450px) {
    .guarantees {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: space-between;
      justify-items: center;
    }
    .logo-image{
        padding: 0 50px;
    }
  }